import { Component, Input, forwardRef } from '@angular/core';
import { FileDTO } from '../../../../dto/file.dto';
import {
  FormGroup,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { FileService } from '../../../../../core/services/file.service';
import { HttpEventType } from '@angular/common/http';
import { TruncatePipe } from '../../../../../core/pipes/truncate.pipe';
import { FileSizePipe } from '../../../../../core/pipes/file-size.pipe';

@Component({
  selector: 'app-multiple-file-upload',
  standalone: true,
  imports: [TruncatePipe, FileSizePipe],
  templateUrl: './multiple-file-upload.component.html',
  styleUrls: ['./multiple-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleFileUploadComponent),
      multi: true,
    },
  ],
})
export class MultipleFileUploadComponent implements ControlValueAccessor {
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  files: FileDTO[] = [];
  progress: { [key: string]: number } = {};
  isDragOver = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private fileService: FileService) {}

  writeValue(values: any[]): void {
    this.files = values;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle the disabled state if necessary
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.handleFileInput(event.dataTransfer.files);
    }
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.handleFileInput(input.files);
    }
  }

  handleFileInput(files: FileList) {
    this.upload(files);
  }

  upload(files: FileList) {
    if (!this.files) {
      this.files = [];
    }

    if (!this.progress) {
      this.progress = {};
    }

    Array.from(files).forEach((file) => {
      const fileDTO: FileDTO = {
        file_name: file.name,
        original_name: file.name,
        type: file.type,
        size: file.size,
        is_new_file: true,
      };

      // Add the fileDTO to the files list immediately
      this.files.push(fileDTO);
      this.formGroup.get(this.formControlName)?.patchValue(this.files);
      this.onChange(this.files);

      this.fileService.uploadFile(file).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total) {
              this.progress[file.name] = Math.round((100 * event.loaded) / event.total);
            }
          } else if (event.type === HttpEventType.Response) {
            if (event.body) {
              // Extract properties from event.body
              const { type, size, file_name, original_name } = event.body;
              // Find the file in the list and update its properties
              const index = this.files.findIndex(f => f.original_name === file.name && f.is_new_file);
              if (index !== -1) {
                this.files[index] = {
                  ...this.files[index],
                  type: type,
                  size: size,
                  file_name: file_name,
                  original_name: original_name
                };
              }
              // Update the progress and patch the form control
              this.progress[file.name] = 100;
              this.formGroup.get(this.formControlName)?.patchValue(this.files);
              this.onChange(this.files);
            }
          }
        },
        (error) => {
          console.error('File upload error:', error);
          this.files = this.files.filter(f => f.file_name !== file.name || !f.is_new_file);
          this.formGroup.get(this.formControlName)?.patchValue(this.files);
          this.onChange(this.files);
          this.progress[file.name] = 0;
        }
      );
    });
  }

  getFileIcon(file: FileDTO): string {   //TODO: correct icons when available
    if (!file || !file.type) {
      return 'assets/images/file-icons/icon-doc.svg'; // Default icon
    }
  
    // Normalize the file extension to lowercase
    const extension = file.type.toLowerCase();
  
    // Mapping object for available file extensions to icons
    const fileIcons: { [key: string]: string } = {
      // Document formats
      '.pdf': 'assets/images/file-icons/icon-pdf.svg',
      '.doc': 'assets/images/file-icons/icon-doc.svg',
      '.docx': 'assets/images/file-icons/icon-doc.svg',
      '.txt': 'assets/images/file-icons/icon-txt.svg',
  
      // Spreadsheet formats
      '.xls': 'assets/images/file-icons/icon-xlx.svg',
      '.xlsx': 'assets/images/file-icons/icon-xlx.svg',
  
      // Presentation formats
      '.ppt': 'assets/images/file-icons/icon-ppt.svg',
      '.pptx': 'assets/images/file-icons/icon-ppt.svg',
  
      // Images
      '.jpeg': 'assets/images/file-icons/icon-jpeg.svg',
      '.jpg': 'assets/images/file-icons/icon-jpeg.svg',
      '.png': 'assets/images/file-icons/icon-png.svg',
  
      // Audio/Video
      '.mp3': 'assets/images/file-icons/icon-mp3.svg',
      '.wav': 'assets/images/file-icons/icon-wav.svg',
      '.mp4': 'assets/images/file-icons/icon-mp4.svg',
      '.avi': 'assets/images/file-icons/icon-avi.svg',
  
      // Data/Other
      '.csv': 'assets/images/file-icons/icon-csv.svg',
      '.zip': 'assets/images/file-icons/icon-zip.svg',
      '.rar': 'assets/images/file-icons/icon-rar.svg',
    };
  
    // Return the matching icon or a generic default icon
    return fileIcons[extension] || 'assets/images/file-icons/icon-doc.svg';
  }


  isUploding() {
    return false;
  }

  removeFile(removeFile: FileDTO) {
    if (removeFile.id) {
      removeFile.is_deleted_file = true;
    } else {
      this.files = this.files.filter((file) => file.file_name !== removeFile.file_name);
    }
    this.onChange(this.files);
  }

  revertFile(revertFile: FileDTO) {
    revertFile.is_deleted_file = false;
    this.onChange(this.files);
  }
}
