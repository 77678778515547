import { Component, Input, forwardRef } from '@angular/core';
import { FileDTO } from '../../../../dto/file.dto';
import {
  FormGroup,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { FileService } from '../../../../../core/services/file.service';
import { HttpEventType } from '@angular/common/http';
import { TruncatePipe } from '../../../../../core/pipes/truncate.pipe';
import { FileSizePipe } from '../../../../../core/pipes/file-size.pipe';

@Component({
  selector: 'app-single-file-upload',
  standalone: true,
  imports: [TruncatePipe, FileSizePipe],
  templateUrl: './single-file-upload.component.html',
  styleUrls: ['./single-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleFileUploadComponent),
      multi: true,
    },
  ],
})
export class SingleFileUploadComponent implements ControlValueAccessor {
  file: FileDTO | null = null;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  progress: number = 0;
  isDragOver = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private fileService: FileService) {}

  writeValue(value: any): void {
    this.file = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle the disabled state if necessary
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.handleFileInput(event.dataTransfer.files);
    }
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.handleFileInput(input.files);
    }
  }

  handleFileInput(files: FileList) {
    this.upload(files[0]);
  }

  upload(file: File) {
    this.file = null;
    this.fileService.uploadFile(file).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total) {
            this.progress = Math.round((100 * event.loaded) / event.total);
          }
        } else if (event.type === HttpEventType.Response) {
          this.file = { ...event.body, is_new_file: true };
          this.formGroup.get(this.formControlName)?.patchValue(this.file);
          this.onChange(this.file);
          this.progress = 100;
        }
      },
      (error) => {
        console.error('File upload error:', error);
        this.progress = 0;
      }
    );
  }

  remove() {
    this.file = null;
    this.formGroup.get(this.formControlName)?.patchValue(null);
    this.onChange(null);
  }

  isUploading() {
    return this.progress > 0 && this.progress < 100;
  }

  getFileIcon(file: FileDTO): string {   //TODO: correct icons when available
    if (!file || !file.type) {
      return 'assets/images/file-icons/icon-doc.svg'; // Default icon
    }
  
    // Normalize the file extension to lowercase
    const extension = file.type.toLowerCase();
  
    // Mapping object for available file extensions to icons
    const fileIcons: { [key: string]: string } = {
      // Document formats
      '.pdf': 'assets/images/file-icons/icon-pdf.svg',
      '.doc': 'assets/images/file-icons/icon-doc.svg',
      '.docx': 'assets/images/file-icons/icon-doc.svg',
      '.txt': 'assets/images/file-icons/icon-txt.svg',
  
      // Spreadsheet formats
      '.xls': 'assets/images/file-icons/icon-xlx.svg',
      '.xlsx': 'assets/images/file-icons/icon-xlx.svg',
  
      // Presentation formats
      '.ppt': 'assets/images/file-icons/icon-ppt.svg',
      '.pptx': 'assets/images/file-icons/icon-ppt.svg',
  
      // Images
      '.jpeg': 'assets/images/file-icons/icon-jpeg.svg',
      '.jpg': 'assets/images/file-icons/icon-jpeg.svg',
      '.png': 'assets/images/file-icons/icon-png.svg',
  
      // Audio/Video
      '.mp3': 'assets/images/file-icons/icon-mp3.svg',
      '.wav': 'assets/images/file-icons/icon-wav.svg',
      '.mp4': 'assets/images/file-icons/icon-mp4.svg',
      '.avi': 'assets/images/file-icons/icon-avi.svg',
  
      // Data/Other
      '.csv': 'assets/images/file-icons/icon-csv.svg',
      '.zip': 'assets/images/file-icons/icon-zip.svg',
      '.rar': 'assets/images/file-icons/icon-rar.svg',
    };
  
    // Return the matching icon or a generic default icon
    return fileIcons[extension] || 'assets/images/file-icons/icon-doc.svg';
  }
}
