@if(isLoading){
  <app-main-loader [height]="150" [width]="150"></app-main-loader>
}@else{
  <form [formGroup]="formGroup">
    <div class="form-header">
      <div class="row">
        @if(title){
          <div class="col-md-6 d-flex align-items-center">
            <h3 class="mb-0">{{title | translate}}</h3>
          </div>
        }
        @if(buttonPosition=='top'){
          <div class="col-md-6 justify-content-lg-end d-flex">
            <app-cancel-button
              [label]="cancelButtonTitle"
              (cancelRequest)="requestClose()">
            </app-cancel-button>

            @if(submitButtonAndNewTitle){
              <app-saving-button
                [isValid]="formGroup.valid"
                [label]="submitButtonAndNewTitle"
                [savingLabel]="submitButtonAndNewLoadingTitle"
                (saveRequest)="requestSubmitForm(false)">
              </app-saving-button>
            }

            <app-saving-button
              [isValid]="formGroup.valid"
              [label]="submitButtonTitle"
              [savingLabel]="submitButtonLoadingText"
              (saveRequest)="requestSubmitForm()">
            </app-saving-button>
          </div>
        }
      </div>
    </div>
    <div class="form-body form-collapsed">
      <div class="form-group row">
        <app-form-error></app-form-error>
          @for (item of formSections; track $index) {
            <div class="row">
              @if(item.title){
                <div class="colmd-12 mb-2">
                  <h4>{{item.title | translate}}</h4>
                </div>
              }
              @for (field of item.fields; track $index) {
                <div class="col-sm-{{field.size || '12' }}">
                  <div class="form-group mb-3">
                    <label [for]="field.id">{{field.label | translate}}
                      @if(field.validators.includes('required')){
                        <app-mandatory-field-indicator></app-mandatory-field-indicator>
                      }
                    </label>
                  @switch (field.type) {
                    @case ('text') {
                      <input
                        type="text"
                        class="form-control"
                        [id]="field.id"
                        [formControlName]="field.formControlName"
                        [placeholder]=" field.placeholder| translate"/>
                    }
                    @case ('number') {
                      <input
                        type="number"
                        class="form-control"
                        [id]="field.id"
                        [formControlName]="field.formControlName"
                        [placeholder]=" field.placeholder| translate"/>
                    }
                    @case ('textarea') {
                      <textarea
                        class="form-control"
                        [rows]="field.rows || 4"
                        [id]="field.id"
                        [formControlName]="field.formControlName"
                        [placeholder]=" field.placeholder| translate">
                      </textarea>
                    }
                    @case ('email') {
                      <input
                        type="email"
                        class="form-control"
                        [id]="field.id"
                        [formControlName]="field.formControlName"
                        [placeholder]=" field.placeholder| translate"/>
                    }
                    @case ('autocomplete') {
                      <ng-select
                          [items]="options$.get(field.formControlName) | async"
                          bindLabel="title_en"
                          [multiple]="true"
                          [typeahead]="typeaheadInputs.get(field.formControlName)!"
                          (search)="onTypeahead($event.term, field.formControlName)"
                          [clearable]="true"
                          [placeholder]=" field.placeholder| translate">
                      </ng-select>
                    }
                    @case ('autocomplete-static') {
                      <ng-select
                        [items]="field.options!"
                        bindLabel="title"
                        bindValue="id"
                        [multiple]="field.isMulitple || false"
                        [placeholder]=" field.placeholder| translate"
                        [clearable]="true"
                        [formControlName]="field.formControlName">
                      </ng-select>
                    }
                    @case ('dropdown') {
                      <select class="form-control" [id]="field.id" [formControlName]="field.formControlName">
                        <option [value]="null" [disabled]="field.validators.includes('required')" selected>{{field.placeholder}}</option>
                        @for (option of field.options; track $index) {
                          <option  class="{{ getClass(option?.color) }}" [value]="option.id">{{option.title}}</option>
                        }
                      </select>
                    }
                    @case ('radiobutton') {
                      <div class="clearfix">
                        @for (option of field.options; track $index) {
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              [formControlName]="field.formControlName"
                              [id]="field.id +'_'+ option.id"
                              [value]="option.id" />
                            <label class="form-check-label" [attr.for]="field.id +'_'+ option.id">{{option.title}}</label>
                          </div>
                        }
                      </div>
                    }
                    @case ('slider') {
                      <input type="range" class="form-range"
                              [formControlName]="field.formControlName"
                              [attr.id]="field.id"
                              [min]="5"
                              [max]="20"
                              [step]="5">
                      <div class="d-flex justify-content-between">
                        @for (option of field.options; track $index) {
                          <span>{{ option.title }}</span>
                        }
                      </div>
                    }
                    @case ('toggle') {
                      <div class="d-flex align-items-center">
                        <p class="me-3 mb-0 marked-primary-text">{{field.label}}</p>
                        <div class="toggle-btn">
                          <input type="checkbox" [id]="field.id" [formControlName]="field.formControlName" />
                        </div>
                      </div>
                    }
                    @case('datepicker'){
                      <app-date-picker
                        [type]="'datepicker'"
                        [id]="field.id"
                        [formGroup]="formGroup"
                        [formControlName]="field.formControlName"
                        [datePickConfig]="field.datePickConfig || {}"
                        [placeholder]="field.placeholder">
                      </app-date-picker>
                    }
                    @case('timepicker'){
                      <app-date-picker
                        [type]="'timepicker'"
                        [id]="field.id"
                        [formGroup]="formGroup"
                        [formControlName]="field.formControlName"
                        [datePickConfig]="field.datePickConfig || {}"
                        [placeholder]="field.placeholder">
                      </app-date-picker>
                    }
                    @case('datetimepicker'){
                      <app-date-picker
                        [type]="'datetimepicker'"
                        [id]="field.id"
                        [formGroup]="formGroup"
                        [formControlName]="field.formControlName"
                        [datePickConfig]="field.datePickConfig || {}"
                        [placeholder]="field.placeholder">
                      </app-date-picker>
                    }
                    @case ('color') {
                      <input type="color"
                        class="form-control"
                        [id]="field.id"
                        [formControlName]="field.formControlName"
                        [placeholder]=" field.placeholder| translate" />
                    }
                    @case ('single-file-upload') {
                      <app-single-file-upload
                        [formGroup]="formGroup"
                        [formControlName]="field.formControlName">
                      </app-single-file-upload>
                    }
                    @case ('multiple-file-upload') {
                      <app-multiple-file-upload
                        [formGroup]="formGroup"
                        [formControlName]="field.formControlName">
                      </app-multiple-file-upload>
                    }
                    @case ('checkbox-table') {
                      @if(field.options && field.tableColumn){
                        <app-basic-table
                          [data]="field.options"
                          [columns]="field.tableColumn"
                          [isPaginationEnabled]="false">
                        </app-basic-table>
                      }
                    }
                  }
                </div>
              </div>
            }



          </div>
          @if(formSections.length-1 != $index){
            <hr>
          }
        }

        @if(buttonPosition=='bottom'){
          <div class="col-md-6">
            <app-side-form-button-group
              [formGroup]="formGroup"
              [buttonPosition]="buttonPosition"
              [cancelButtonTitle]="cancelButtonTitle"
              [submitButtonAndNewTitle]="submitButtonAndNewTitle"
              [submitButtonAndNewLoadingTitle]="submitButtonAndNewLoadingTitle"
              [submitButtonTitle]="submitButtonTitle"
              [submitButtonLoadingText]="submitButtonLoadingText"
              (cancelRequest)="requestClose()"
              (saveRequest)="requestSubmitForm($event)">
            </app-side-form-button-group>
          </div>
        }

      </div>
    </div>
  </form>
}

